<template>
  <div>
    <Modal @close="$emit('close')">
      <form @submit.prevent="guadar_conf">
        <div class="title">Configuración</div>
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-3">Nombre</label>
            <div class="col-sm-9"><input v-model="conf.nombre" type="text" name="nombre" id="nombre" class="form-control" :disabled="conf.id"></div>
          </div>
          <div class="row form-group">
            <label for="valor" class="col-form-label col-sm-3">Valor</label>
            <div class="col-sm-9"><input v-model="conf.valor" type="text" name="valor" id="valor" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="tipo" class="col-form-label col-sm-3">Tipo</label>
            <div class="col-sm-9">
              <select v-model="conf.tipo" name="tipo" id="tipo" class="form-control">
                <option value="string">Texto plano</option>
                <option value="numeric">Número</option>
                <option value="number">Número</option>
                <option value="array">Array</option>
                <option value="object">Objeto</option>
                <option value="boolean">Booleano</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="descripcion" class="col-form-label col-sm-3">Descripción</label>
            <div class="col-sm-9">
              <textarea v-model="conf.descripcion" name="descripcion" id="descripcion" cols="30" rows="10" class="form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar</button>
              <button class="btn btn-danger" type="button" @click.prevent="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/analyticpoint/api/comite/configuracion';

export default {
  components: {
    Modal
  },
  props: {
    config: {
      type: Object
    }
  },
  data() {
    return {
      conf: {
        nombre: null,
        valor: null,
        tipo: 'string',
        descripcion: null
      },
      search: ['nombre','valor','tipo','descripcion']
    }
  },
  mounted() {
    if (this.config)
      this.conf = this.$helper.clone(this.config);
  },
  methods: {
    async guadar_conf() {
      try {
        let res = null;
        if (!this.conf.id)
          res = (await api.crear_configuracion(this.conf)).data;
        else
          res = (await api.actualizar_configuracion(this.conf.id, this.conf)).data;

        this.$log.info('res', res);
        this.$emit('update');
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>